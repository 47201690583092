var ShareService = /** @class */ (function () {
    function ShareService() {
        var _this = this;
        this.requestParams = {
            additionalEmailInfo: function (job) { return '?subject=' + encodeURIComponent(job.title) + '&body=' + _this.description(job, window.location.href) + _this.additionalEmailInfo(job); },
            facebook: function () { return '?display=popup&app_id=' + _this.config.keys.facebook + '&href=' + encodeURIComponent(window.location.href) + '&redirect_uri=' + encodeURIComponent('https://www.facebook.com/') + '&source=facebook'; },
            twitter: function (job) { return '?text=' + encodeURIComponent(_this.description(job)) + '&url=' + encodeURIComponent(window.location.href) + '&source=twitter'; },
            linkedin: function (job) { return '?mini=true&source=Bullhorn%20Career%20Portal&title=' + encodeURIComponent(_this.description(job)) + '&url=' + encodeURIComponent(window.location.href) + '&source=linkedin'; },
            email: function (job) { return '?subject=' + encodeURIComponent(job.title) + '&body=' + _this.description(job, window.location.href); },
        };
    }
    Object.defineProperty(ShareService.prototype, "config", {
        get: function () {
            return {
                url: {
                    facebook: 'https://www.facebook.com/dialog/share',
                    twitter: 'https://twitter.com/intent/tweet',
                    linkedin: 'https://www.linkedin.com/shareArticle',
                },
                keys: {
                    facebook: '1439597326343190',
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    ShareService.prototype.emailLink = function (job) {
        return 'mailto:' + this.requestParams.email(job);
    };
    ShareService.prototype.facebook = function (job) {
        window.open(this.config.url.facebook + this.requestParams.facebook(job));
    };
    ShareService.prototype.linkedin = function (job) {
        window.open(this.config.url.linkedin + this.requestParams.linkedin(job));
    };
    ShareService.prototype.sendEmailLink = function (job, email) {
        email = email || '';
        return 'mailto:' + email + this.requestParams.additionalEmailInfo(job);
    };
    ShareService.prototype.twitter = function (job) {
        window.open(this.config.url.twitter + this.requestParams.twitter(job));
    };
    ShareService.prototype.additionalEmailInfo = function (job) {
        var body = '\n';
        if (job.title) {
            body += '\nTitle: Jobs';
        }
        if (job.publishedCategory && job.publishedCategory.name) {
            body += '\n' + 'Category: ' + job.publishedCategory.name;
        }
        if (job.address) {
            var location_1 = '\n' + 'Location: ';
            if (job.address.city && job.address.state) {
                body += location_1 + job.address.city + ', ' + job.address.state + '\n';
            }
            else if (job.address.city) {
                body += location_1 + job.address.city + '\n';
            }
            else if (job.address.state) {
                body += location_1 + job.address.state + '\n';
            }
        }
        return encodeURIComponent(body);
    };
    ShareService.prototype.description = function (job, url) {
        if (url) {
            return 'Check out this ' + job.title + ' job: ' + encodeURIComponent(url);
        }
        return 'Check out this ' + job.title + ' job!';
    };
    return ShareService;
}());
export { ShareService };
