import * as ms from 'ms';
import { SettingsService } from '../settings/settings.service';
import * as i0 from "@angular/core";
import * as i1 from "@nguniversal/express-engine/tokens";
var ServerResponseService = /** @class */ (function () {
    function ServerResponseService(response) {
        this.response = response;
    }
    ServerResponseService.prototype.getHeader = function (key) {
        return this.response.getHeader(key);
    };
    ServerResponseService.prototype.setHeader = function (key, value) {
        if (this.response) {
            this.response.header(key, value);
        }
        return this;
    };
    ServerResponseService.prototype.appendHeader = function (key, value, delimiter) {
        if (delimiter === void 0) { delimiter = ','; }
        if (this.response) {
            var current = this.getHeader(key);
            if (!current) {
                return this.setHeader(key, value);
            }
            var newValue = current.split(delimiter).concat([value]).filter(function (el, i, a) { return i === a.indexOf(el); })
                .join(delimiter);
            this.response.header(key, newValue);
        }
        return this;
    };
    ServerResponseService.prototype.setHeaders = function (dictionary) {
        var _this = this;
        if (this.response) {
            Object.keys(dictionary).forEach(function (key) { return _this.setHeader(key, dictionary[key]); });
        }
        return this;
    };
    ServerResponseService.prototype.setStatus = function (code, message) {
        if (this.response) {
            this.response.statusCode = code;
            if (message) {
                this.response.statusMessage = message;
            }
        }
        return this;
    };
    ServerResponseService.prototype.setNotFound = function (message) {
        if (message === void 0) { message = 'not found'; }
        if (SettingsService.isServer) {
            this.response.status(404);
            this.response.statusMessage = message;
        }
        return this;
    };
    ServerResponseService.prototype.setUnauthorized = function (message) {
        if (message === void 0) { message = 'Unauthorized'; }
        if (this.response) {
            this.response.statusCode = 401;
            this.response.statusMessage = message;
        }
        return this;
    };
    ServerResponseService.prototype.setCachePrivate = function () {
        if (this.response) {
            this.setCache('private');
        }
        return this;
    };
    ServerResponseService.prototype.setCacheNone = function () {
        if (this.response) {
            this.setHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
            this.setHeader('Pragma', 'no-cache');
        }
        return this;
    };
    ServerResponseService.prototype.setCache = function (directive, maxAge, smaxAge) {
        if (this.response) {
            // tslint:disable-next-line:max-line-length
            if (smaxAge) {
                this.setHeader('Cache-Control', directive + ", max-age=" + (maxAge ? ms(maxAge) / 1000 : 0) + ", s-maxage=" + ms(smaxAge) / 1000);
            }
            else {
                this.setHeader('Cache-Control', directive + ", max-age=" + (maxAge ? ms(maxAge) / 1000 : 0));
            }
            this.setHeader('Expires', maxAge ? new Date(Date.now() + ms(maxAge)).toUTCString() : new Date(Date.now()).toUTCString());
        }
        return this;
    };
    ServerResponseService.prototype.setError = function (message) {
        if (message === void 0) { message = 'internal server error'; }
        if (this.response) {
            this.response.statusCode = 500;
            this.response.statusMessage = message;
        }
        return this;
    };
    ServerResponseService.ngInjectableDef = i0.defineInjectable({ factory: function ServerResponseService_Factory() { return new ServerResponseService(i0.inject(i1.RESPONSE, 8)); }, token: ServerResponseService, providedIn: "root" });
    return ServerResponseService;
}());
export { ServerResponseService };
