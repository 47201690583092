import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../settings/settings.service';
var ApplyService = /** @class */ (function () {
    function ApplyService(http, settings) {
        this.http = http;
        this.settings = settings;
    }
    Object.defineProperty(ApplyService.prototype, "baseUrl", {
        get: function () {
            var service = SettingsService.settings.service;
            var port = service.port ? service.port : 443;
            var scheme = "http" + (port === 443 ? 's' : '');
            return scheme + "://public-rest" + service.swimlane + ".bullhornstaffing.com:" + port + "/rest-services/" + service.corpToken + "/apply";
        },
        enumerable: true,
        configurable: true
    });
    ApplyService.prototype.apply = function (id, params, formData) {
        var applyParams = this.assembleParams(params);
        return this.http.post(this.baseUrl + "/" + id + "/raw?" + applyParams, formData);
    };
    ApplyService.prototype.assembleParams = function (data) {
        var params = [];
        params.push("externalID=Resume");
        params.push("type=Resume");
        for (var key in data) {
            if (!data.hasOwnProperty(key)) {
                continue;
            }
            if (!data[key]) {
                continue;
            }
            var value = data[key];
            params.push(key + "=" + value);
        }
        return params.join('&');
    };
    return ApplyService;
}());
export { ApplyService };
