import { OnInit } from '@angular/core';
import { FormUtils, NovoModalRef, NovoModalParams, TextBoxControl, FileControl, PickerControl, SelectControl, NovoToastService, CheckboxControl, } from 'novo-elements';
import { TranslateService } from 'chomsky';
import { SettingsService } from '../../../services/settings/settings.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { ApplyService } from '../../../services/apply/apply.service';
var ApplyModalComponent = /** @class */ (function () {
    function ApplyModalComponent(formUtils, params, modalRef, applyService, analytics, toaster) {
        this.formUtils = formUtils;
        this.params = params;
        this.modalRef = modalRef;
        this.applyService = applyService;
        this.analytics = analytics;
        this.toaster = toaster;
        this.loading = true;
        this.hasError = false;
        this.formControls = [this.firstName, this.lastName, this.email, this.phoneNumber];
        this.eeocControls = [];
        this.applying = false;
        this.privacyPolicyURL = SettingsService.settings.privacyConsent.privacyPolicyUrl;
        this.consentCheckbox = SettingsService.settings.privacyConsent.consentCheckbox;
        this.usePrivacyPolicyUrl = SettingsService.settings.privacyConsent.usePrivacyPolicyUrl;
        this.privacyStatementParagraphs = SettingsService.settings.privacyConsent.privacyStatementParagraphs.join('\r\n');
        this.showCategory = SettingsService.settings.service.showCategory;
        this.APPLIED_JOBS_KEY = 'APPLIED_JOBS_KEY';
        this.toaster.parentViewContainer = this.params['viewContainer'];
    }
    ApplyModalComponent.prototype.ngOnInit = function () {
        this.job = this.params['job'];
        this.source = this.params['source'];
        this.setupForm();
    };
    ApplyModalComponent.prototype.setupForm = function () {
        var _a;
        this.firstName = new TextBoxControl({
            key: 'firstName',
            label: TranslateService.translate('FIRST_NAME'),
            required: true,
            hidden: false,
            value: '',
        });
        this.lastName = new TextBoxControl({
            key: 'lastName',
            label: TranslateService.translate('LAST_NAME'),
            required: true,
            hidden: false,
            value: '',
        });
        this.email = new TextBoxControl({
            key: 'email',
            label: TranslateService.translate('EMAIL'),
            type: 'email',
            required: true,
            hidden: false,
            value: '',
        });
        this.phoneNumber = new TextBoxControl({
            key: 'phone',
            label: TranslateService.translate('PHONE'),
            type: 'tel',
            required: false,
            hidden: false,
            value: '',
        });
        this.genderRaceEthnicity = [
            new SelectControl({
                key: 'gender',
                label: TranslateService.translate('EEOC.GENDER_LABEL'),
                required: SettingsService.settings.eeoc.genderRaceEthnicity,
                hidden: false,
                options: [
                    { value: 'M', label: TranslateService.translate('EEOC.GENDER_MALE') },
                    { value: 'F', label: TranslateService.translate('EEOC.GENDER_FEMALE') },
                    { value: 'D', label: TranslateService.translate('EEOC.GENDER_ND') },
                ],
            }), new PickerControl({
                key: 'ethnicity',
                label: TranslateService.translate('EEOC.RACE_ETHNICITY_LABEL'),
                required: SettingsService.settings.eeoc.genderRaceEthnicity,
                hidden: false,
                multiple: true,
                placeholder: TranslateService.translate('EEOC.SELECT_ALL'),
                config: {
                    options: [
                        { value: 'HL', label: TranslateService.translate('EEOC.RACE_ETHNICTY_HL') },
                        { value: 'WH', label: TranslateService.translate('EEOC.RACE_ETHNICTY_WH') },
                        { value: 'BL', label: TranslateService.translate('EEOC.RACE_ETHNICTY_BL') },
                        { value: 'AS', label: TranslateService.translate('EEOC.RACE_ETHNICTY_AS') },
                        { value: 'NP', label: TranslateService.translate('EEOC.RACE_ETHNICTY_NP') },
                        { value: 'IA', label: TranslateService.translate('EEOC.RACE_ETHNICTY_IA') },
                        { value: 'DN', label: TranslateService.translate('EEOC.RACE_ETHNICTY_DN') },
                    ],
                },
            }),
        ];
        this.veteran = [
            new SelectControl({
                key: 'veteran',
                label: TranslateService.translate('EEOC.VETERAN_LABEL'),
                description: TranslateService.translate('EEOC.VETERAN_DESCRIPTION'),
                required: SettingsService.settings.eeoc.veteran,
                hidden: false,
                options: [
                    { value: 'P', label: TranslateService.translate('EEOC.VETERAN_P') },
                    { value: 'V', label: TranslateService.translate('EEOC.VETERAN_V') },
                    { value: 'N', label: TranslateService.translate('EEOC.VETERAN_N') },
                    { value: 'D', label: TranslateService.translate('EEOC.VETERAN_D') },
                ],
            }),
        ];
        this.disability = [
            new SelectControl({
                key: 'disability',
                label: TranslateService.translate('EEOC.DISABILITY_LABEL'),
                description: TranslateService.translate('EEOC.DISABILITY_DESCRIPTION'),
                required: SettingsService.settings.eeoc.disability,
                hidden: false,
                options: [
                    { value: 'Y', label: TranslateService.translate('EEOC.DISABILITY_Y') },
                    { value: 'N', label: TranslateService.translate('EEOC.DISABILITY_N') },
                    { value: 'D', label: TranslateService.translate('EEOC.DISABILITY_D') },
                ],
            }),
        ];
        this.resume = new FileControl({
            key: 'resume',
            required: false,
            hidden: false,
            description: TranslateService.translate('ACCEPTED_RESUME') + " " + SettingsService.settings.acceptedResumeTypes.toString(),
        });
        this.formControls = [this.firstName, this.lastName, this.email, this.phoneNumber, this.resume];
        var eeoc = SettingsService.settings.eeoc;
        for (var field in eeoc) {
            if (eeoc[field]) {
                (_a = this.eeocControls).push.apply(_a, this[field]);
            }
        }
        this.consentControl = new CheckboxControl({
            key: 'consent',
            required: SettingsService.settings.privacyConsent.consentCheckbox,
            hidden: false,
            interactions: [
                {
                    event: 'change',
                    script: function (FAPI) {
                        if (!FAPI.getValue('consent')) {
                            FAPI.markAsInvalid('consent');
                        }
                    },
                },
            ],
        });
        this.form = this.formUtils.toFormGroup(this.formControls.concat(this.eeocControls, [this.consentControl]));
        this.loading = false;
    };
    ApplyModalComponent.prototype.close = function (applied) {
        if (applied === void 0) { applied = false; }
        if (applied) {
            this.analytics.trackEvent("Success applying to job " + this.job.id);
        }
        else {
            this.analytics.trackEvent("Close apply form without applying for job " + this.job.id);
        }
        this.modalRef.close(undefined);
    };
    ApplyModalComponent.prototype.save = function () {
        if (this.form.valid) {
            this.applying = true;
            this.analytics.trackEvent("Apply to Job: " + this.job.id);
            var requestParams = {
                firstName: encodeURIComponent(this.form.value.firstName),
                lastName: encodeURIComponent(this.form.value.lastName),
                email: encodeURIComponent(this.form.value.email),
                phone: encodeURIComponent(this.form.value.phone || ''),
                format: this.form.value.resume && this.form.value.resume[0].name.substring(this.form.value.resume[0].name.lastIndexOf('.') + 1) || 'TEXT',
            };
            if (this.form.value.gender) {
                requestParams.gender = encodeURIComponent(this.form.value.gender);
            }
            if (this.form.value.ethnicity) {
                requestParams.ethnicity = encodeURIComponent(this.form.value.ethnicity);
            }
            if (this.form.value.veteran) {
                requestParams.veteran = encodeURIComponent(this.form.value.veteran);
            }
            if (this.form.value.disability) {
                requestParams.disability = encodeURIComponent(this.form.value.disability);
            }
            if (this.source) {
                requestParams.source = this.source;
            }
            var formData = new FormData();
            if (this.form.value.resume && this.form.value.resume.length) {
                formData.append('resume', this.form.value.resume[0].file);
            }
            else {
                var fileData = 'First Name:' + this.form.value.firstName;
                fileData = fileData + '\n' + 'Last Name:' + this.form.value.lastName;
                fileData = fileData + '\n' + 'City:' + this.form.value.city;
                fileData = fileData + '\n' + 'State:' + this.form.value.state;
                fileData = fileData + '\n' + 'Email:' + this.form.value.email;
                fileData = fileData + '\n' + 'Phone:' + this.form.value.phone;
                formData.append('resume', new File([fileData], 'resume', { type: 'text/plain', lastModified: new Date().getTime() }), 'resume');
            }
            this.applyService.apply(this.job.id, requestParams, formData).subscribe(this.applyOnSuccess.bind(this), this.applyOnFailure.bind(this));
        }
    };
    ApplyModalComponent.prototype.viewPrivacyPolicy = function () {
        window.open(this.privacyPolicyURL);
    };
    ApplyModalComponent.prototype.applyOnSuccess = function (res) {
        var toastOptions = {
            theme: 'success',
            icon: 'check',
            title: TranslateService.translate('THANK_YOU'),
            message: TranslateService.translate('YOU_WILL_BE_CONTACTED'),
            position: 'growlTopRight',
            hideDelay: 3000,
        };
        this.toaster.alert(toastOptions);
        var alreadyAppliedJobs = sessionStorage.getItem(this.APPLIED_JOBS_KEY);
        if (alreadyAppliedJobs) {
            var alreadyAppliedJobsArray = JSON.parse(alreadyAppliedJobs);
            alreadyAppliedJobsArray.push(this.job.id);
            sessionStorage.setItem(this.APPLIED_JOBS_KEY, JSON.stringify(alreadyAppliedJobsArray));
        }
        else {
            sessionStorage.setItem(this.APPLIED_JOBS_KEY, JSON.stringify([this.job.id]));
        }
        this.applying = false;
        this.close(true);
    };
    ApplyModalComponent.prototype.applyOnFailure = function (res) {
        this.hasError = true;
        this.applying = false;
    };
    return ApplyModalComponent;
}());
export { ApplyModalComponent };
