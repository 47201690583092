import { OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { SearchService } from '../services/search/search.service';
import { Title, Meta } from '@angular/platform-browser';
import { SettingsService } from '../services/settings/settings.service';
import { Router } from '@angular/router';
import { TranslateService } from 'chomsky';
var JobListComponent = /** @class */ (function () {
    function JobListComponent(http, titleService, meta, router) {
        this.http = http;
        this.titleService = titleService;
        this.meta = meta;
        this.router = router;
        this.sidebarVisible = false;
        this.displaySidebar = new EventEmitter();
        this.showLoading = new EventEmitter();
        this.showError = new EventEmitter();
        this.jobs = [];
        this._loading = true;
        this.moreAvailable = true;
        this.total = '...';
        this.jobInfoChips = SettingsService.settings.service.jobInfoChips;
        this.showCategory = SettingsService.settings.service.showCategory;
        this.start = 0;
    }
    JobListComponent.prototype.ngOnChanges = function (changes) {
        this.getData();
    };
    JobListComponent.prototype.getData = function (loadMore) {
        if (loadMore === void 0) { loadMore = false; }
        this.start = loadMore ? (this.start + 30) : 0;
        this.titleService.setTitle(SettingsService.settings.companyName + " - Careers");
        var description = TranslateService.translate('PAGE_DESCRIPTION');
        this.meta.updateTag({ name: 'og:description', content: description });
        this.meta.updateTag({ name: 'twitter:description', content: description });
        this.meta.updateTag({ name: 'description', content: description });
        this.http.getjobs(this.filter, { start: this.start }).subscribe(this.onSuccess.bind(this), this.onFailure.bind(this));
    };
    JobListComponent.prototype.loadMore = function () {
        this.getData(true);
    };
    JobListComponent.prototype.openSidebar = function () {
        this.displaySidebar.emit(true);
    };
    JobListComponent.prototype.loadJob = function (jobId) {
        this.router.navigate(["jobs/" + jobId]);
        this.loading = true;
    };
    Object.defineProperty(JobListComponent.prototype, "loading", {
        get: function () {
            return this._loading;
        },
        set: function (value) {
            this.showLoading.emit(value);
            this._loading = value;
        },
        enumerable: true,
        configurable: true
    });
    JobListComponent.prototype.onSuccess = function (res) {
        if (this.start > 0) {
            this.jobs = this.jobs.concat(res.data);
        }
        else {
            this.jobs = res.data;
        }
        this.total = res.total;
        this.moreAvailable = (res.count === 30);
        this.loading = false;
    };
    JobListComponent.prototype.onFailure = function (res) {
        this.loading = false;
        this.jobs = [];
        this.total = 0;
        this.moreAvailable = false;
        this.showError.emit(true);
    };
    return JobListComponent;
}());
export { JobListComponent };
