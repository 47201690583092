import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isMobileNavOpen = false;
  openSubMenu = '';

  constructor() { }

  ngOnInit() {
  }

  toggleMobileNav() {
    this.isMobileNavOpen = !this.isMobileNavOpen;
  }
  toggleSubMenu(menu: string) {
    this.openSubMenu = this.openSubMenu === menu ? '' : menu;
  }

}
