/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./structured-seo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./structured-seo.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
var styles_StructuredSeoComponent = [i0.styles];
var RenderType_StructuredSeoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StructuredSeoComponent, data: {} });
export { RenderType_StructuredSeoComponent as RenderType_StructuredSeoComponent };
export function View_StructuredSeoComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_StructuredSeoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-structured-seo", [], [[8, "innerHTML", 1]], null, null, View_StructuredSeoComponent_0, RenderType_StructuredSeoComponent)), i1.ɵdid(1, 573440, null, 0, i2.StructuredSeoComponent, [i1.Renderer2, i3.DOCUMENT, i4.DatePipe], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).html; _ck(_v, 0, 0, currVal_0); }); }
var StructuredSeoComponentNgFactory = i1.ɵccf("app-structured-seo", i2.StructuredSeoComponent, View_StructuredSeoComponent_Host_0, { jobData: "jobData" }, {}, []);
export { StructuredSeoComponentNgFactory as StructuredSeoComponentNgFactory };
