/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/novo-elements/novo-elements.ngfactory";
import * as i3 from "novo-elements";
import * as i4 from "chomsky";
import * as i5 from "@angular/common";
import * as i6 from "../sidebar/sidebar.component.ngfactory";
import * as i7 from "../sidebar/sidebar.component";
import * as i8 from "../services/search/search.service";
import * as i9 from "../job-list/job-list.component.ngfactory";
import * as i10 from "../job-list/job-list.component";
import * as i11 from "@angular/platform-browser";
import * as i12 from "@angular/router";
import * as i13 from "./main-page.component";
var styles_MainPageComponent = [i0.styles];
var RenderType_MainPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainPageComponent, data: {} });
export { RenderType_MainPageComponent as RenderType_MainPageComponent };
function View_MainPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "novo-loading", [], [[8, "className", 0]], null, null, i2.View_ɵq_0, i2.RenderType_ɵq)), i1.ɵdid(1, 49152, null, 0, i3.ɵq, [], null, null)], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).theme || ""); _ck(_v, 0, 0, currVal_0); }); }
function View_MainPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "error-state"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "bhi-caution"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("ERROR_HAS_OCCURED")); _ck(_v, 3, 0, currVal_0); }); }
export function View_MainPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainPageComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainPageComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "frame"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i5.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { display: 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 2, "app-sidebar", [], [[2, "active", null]], [[null, "newFilter"], [null, "toggleSidebar"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("newFilter" === en)) {
        var pd_0 = (_co.onSidebarFilter($event) !== false);
        ad = (pd_0 && ad);
    } if (("toggleSidebar" === en)) {
        var pd_1 = (_co.toggleSidebar($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_SidebarComponent_0, i6.RenderType_SidebarComponent)), i1.ɵdid(8, 278528, null, 0, i5.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(9, 49152, null, 0, i7.SidebarComponent, [i8.SearchService], { display: [0, "display"] }, { newFilter: "newFilter", toggleSidebar: "toggleSidebar" }), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-job-list", [], null, [[null, "displaySidebar"], [null, "showLoading"], [null, "showError"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("displaySidebar" === en)) {
        var pd_0 = (_co.toggleSidebar($event) !== false);
        ad = (pd_0 && ad);
    } if (("showLoading" === en)) {
        var pd_1 = (_co.handleListLoad($event) !== false);
        ad = (pd_1 && ad);
    } if (("showError" === en)) {
        var pd_2 = (_co.handleError($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i9.View_JobListComponent_0, i9.RenderType_JobListComponent)), i1.ɵdid(12, 573440, null, 0, i10.JobListComponent, [i8.SearchService, i11.Title, i11.Meta, i12.Router], { filter: [0, "filter"], filterCount: [1, "filterCount"], sidebarVisible: [2, "sidebarVisible"] }, { displaySidebar: "displaySidebar", showLoading: "showLoading", showError: "showError" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading && !_co.error); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.error; _ck(_v, 3, 0, currVal_1); var currVal_2 = _ck(_v, 6, 0, ((_co.loading || _co.error) ? "none" : "flex")); _ck(_v, 5, 0, currVal_2); var currVal_4 = _co.sidebarCss; _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.displaySidebar; _ck(_v, 9, 0, currVal_5); var currVal_6 = _co.listFilter; var currVal_7 = _co.filterCount; var currVal_8 = _co.displaySidebar; _ck(_v, 12, 0, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 9).display; _ck(_v, 7, 0, currVal_3); }); }
export function View_MainPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main-page", [], null, null, null, View_MainPageComponent_0, RenderType_MainPageComponent)), i1.ɵdid(1, 49152, null, 0, i13.MainPageComponent, [], null, null)], null, null); }
var MainPageComponentNgFactory = i1.ɵccf("app-main-page", i13.MainPageComponent, View_MainPageComponent_Host_0, {}, {}, []);
export { MainPageComponentNgFactory as MainPageComponentNgFactory };
