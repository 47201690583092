<header class="site-header">
  <div class="wrap">
    <div class="title-area">
      <a href="https://www.aplus-staffing.com/" class="custom-logo-link" rel="home"><img width="396" height="80" src="https://www.aplus-staffing.com/wp-content/uploads/2018/07/APlus_full-color-primary-logo-396-80.png" class="custom-logo" alt="A Plus Staffing" srcset="https://www.aplus-staffing.com/wp-content/uploads/2018/07/APlus_full-color-primary-logo-396-80.png 396w, https://www.aplus-staffing.com/wp-content/uploads/2018/07/APlus_full-color-primary-logo-396-80-300x61.png 300w" sizes="(max-width: 396px) 100vw, 396px"></a>
      <p class="site-title"><a href="https://www.aplus-staffing.com/">A Plus Staffing</a></p>
    </div>
    <div class="widget-area header-widget-area">
      <section id="text-2" class="widget widget_text">
        <div class="widget-wrap">
          <div class="textwidget">
            <p>
              <a style="color: grey;" href="tel:+16143289994">
                <br>
                <span class="headphone"><i class="las la-phone"></i></span>(614) 328-9994
                <br>
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
    <button class="menu-toggle" [ngClass]="{ 'activated': isMobileNavOpen }" aria-expanded="false" aria-pressed="false" role="button" id="genesis-mobile-nav-primary" (click)="toggleMobileNav()">Menu<span></span></button>
    <nav class="nav-primary genesis-responsive-menu" [ngClass]="{ 'visible': isMobileNavOpen }" aria-label="Main" id="genesis-nav-primary">
      <div class="wrap">
        <ul id="menu-header-menu" class="menu genesis-nav-menu menu-primary js-superfish sf-js-enabled sf-arrows" style="touch-action: pan-y;">
          <li id="menu-item-1306" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1306" (click)="toggleSubMenu('aboutUs')"><a href="https://www.aplus-staffing.com/about-us/" class="sf-with-ul"><span>About Us</span></a>
            <button class="sub-menu-toggle" aria-expanded="false" aria-pressed="false"><span class="screen-reader-text">Menu</span></button>
            <ul class="sub-menu" [ngClass]="{ 'open': openSubMenu === 'aboutUs' }">
              <li id="menu-item-1975" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1975"><a href="https://www.aplus-staffing.com/blog/"><span>Blog</span></a></li>
            </ul>
          </li>
          <li id="menu-item-1854" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1854" (click)="toggleSubMenu('industries')"><a href="/administrative-and-clerical/" class="sf-with-ul"><span>Industries</span></a>
            <button class="sub-menu-toggle" aria-expanded="false" aria-pressed="false"><span class="screen-reader-text">Menu</span></button>
            <ul class="sub-menu" [ngClass]="{ 'open': openSubMenu === 'industries' }">
              <li id="menu-item-1919" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1919"><a href="https://www.aplus-staffing.com/professional-search/"><span>Executive & Professional Search</span></a></li>
              <li id="menu-item-1857" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1857"><a href="https://www.aplus-staffing.com/legal-staffing/"><span>Legal</span></a></li>
              <li id="menu-item-1858" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1858"><a href="https://www.aplus-staffing.com/administrative-and-clerical/"><span>Admin & Clerical</span></a></li>
              <li id="menu-item-1856" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1856"><a href="https://www.aplus-staffing.com/health-care/"><span>Healthcare</span></a></li>
              <li id="menu-item-1856" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1856"><a href="https://www.aplus-staffing.com/labor-and-light-industrial/"><span>LABOR AND LIGHT INDUSTRIAL</span></a></li>
            </ul>
          </li>
          <li id="menu-item-1483" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1483" (click)="toggleSubMenu('jobSeekers')"><a href="https://www.aplus-staffing.com/job-seekers/" class="sf-with-ul"><span>Job Seekers</span></a>
            <button class="sub-menu-toggle" aria-expanded="false" aria-pressed="false"><span class="screen-reader-text">Menu</span></button>
            <ul class="sub-menu" [ngClass]="{ 'open': openSubMenu === 'jobSeekers' }">
              <li id="menu-item-2026" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2026"><a href="https://www.aplus-staffing.com/job-listings/"><span>Job Listings</span></a></li>
            </ul>
          </li>
          <li id="menu-item-1480" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1480" (click)="toggleSubMenu('employers')"><a href="https://www.aplus-staffing.com/employers/" class="sf-with-ul"><span>Employers</span></a>
            <button class="sub-menu-toggle" aria-expanded="false" aria-pressed="false"><span class="screen-reader-text">Menu</span></button>
            <ul class="sub-menu" [ngClass]="{ 'open': openSubMenu === 'employers' }">
              <li id="menu-item-1482" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1482"><a href="https://www.aplus-staffing.com/employers/find-an-employee/"><span>Find An Employee</span></a></li>
            </ul>
          </li>
          <li id="menu-item-1304" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1304"><a href="https://www.aplus-staffing.com/contact-us/"><span>Contact Us</span></a></li>
        </ul>
      </div>
    </nav>
  </div>
</header>