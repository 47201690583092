/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/novo-elements/novo-elements.ngfactory";
import * as i3 from "novo-elements";
import * as i4 from "chomsky";
import * as i5 from "./error-modal.component";
var styles_ErrorModalComponent = [i0.styles];
var RenderType_ErrorModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorModalComponent, data: {} });
export { RenderType_ErrorModalComponent as RenderType_ErrorModalComponent };
export function View_ErrorModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "novo-notification", [["data-automation-id", "error-modal"], ["type", "error"]], null, null, null, i2.View_ɵe_0, i2.RenderType_ɵe)), i1.ɵdid(1, 114688, null, 0, i3.ɵe, [i3.NovoModalRef], { type: [0, "type"] }, null), (_l()(), i1.ɵeld(2, 0, null, 1, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, 2, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, 4, 3, "button", [["data-automation-id", "error-modal-confirm"], ["icon", "check"], ["theme", "primary"]], [[1, "theme", 0], [1, "color", 0], [1, "icon", 0], [1, "loading", 0], [1, "side", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ɵp_0, i2.RenderType_ɵp)), i1.ɵdid(7, 49152, null, 0, i3.ɵp, [], { theme: [0, "theme"], icon: [1, "icon"] }, null), (_l()(), i1.ɵted(8, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "error"; _ck(_v, 1, 0, currVal_0); var currVal_8 = "primary"; var currVal_9 = "check"; _ck(_v, 7, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.params["title"]; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.params["message"]; _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 7).theme; var currVal_4 = i1.ɵnov(_v, 7).color; var currVal_5 = i1.ɵnov(_v, 7).icon; var currVal_6 = i1.ɵnov(_v, 7).loading; var currVal_7 = i1.ɵnov(_v, 7).side; _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("OKAY")); _ck(_v, 8, 0, currVal_10); }); }
export function View_ErrorModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "error-modal", [], null, null, null, View_ErrorModalComponent_0, RenderType_ErrorModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.ErrorModalComponent, [i3.NovoModalParams, i3.NovoModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorModalComponentNgFactory = i1.ɵccf("error-modal", i5.ErrorModalComponent, View_ErrorModalComponent_Host_0, {}, {}, []);
export { ErrorModalComponentNgFactory as ErrorModalComponentNgFactory };
